// Auction.js
import React, { useEffect, useState } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import EcommerceCardProduct from '../components/ecommerceCardProduct/EcommerceCardProduct';
import Container from '../components/container/Container';
import EcommerceFilterProducts from '../components/ecommerceFilterProducts/EcommerceFilterProducts';
import { Config } from '../Config';

const Auction = () => {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
        // Fetch data from API
        const fetchData = async () => {
            try {
                const response = await fetch(`${Config.headlessConfig.baseURL}/acp/api/content/items/carAuctions`, {
                    method: 'GET',
                    headers: {
                        'api-key': Config.headlessConfig.API_KEY,
                    },
                });
                const data = await response.json();
                setProducts(data);
                setFilteredProducts(data.reverse()); // Set initial filtered products
            } catch (error) {
                console.error("Error fetching the car auctions data:", error);
            }
        };

        fetchData();
    }, []);

    const handleFilter = (filters) => {
        const { carBrand, carModel, milleage, startingPrice } = filters;
        const filtered = products.filter((product) => {
            const matchesBrand = carBrand ? product.carBrand === carBrand : true;
            const matchesModel = carModel ? product.carModel === carModel : true;
            const matchesMilleage = milleage ? product.milleage <= parseInt(milleage) : true;
            const matchesPrice = startingPrice ? product.startingPrice <= parseInt(startingPrice) : true;
            return matchesBrand && matchesModel && matchesMilleage && matchesPrice;
        });
        setFilteredProducts(filtered);
    };

    return (
        <>
            <Header showContactBelt={false}/>
            <Container>
                <EcommerceFilterProducts arrayLength={products?.length} onFilter={handleFilter} />
                {/* Render filtered products */}
                {filteredProducts.map((product) => (
                    <EcommerceCardProduct
                        key={product._id}
                        id={product._id}
                        title={product.carFullName}
                        img={"https://smart-cars.pl/acp/storage/uploads" + product.coverPhoto.path}
                        auctionDate={product.auctionDate}
                        expertDescription={product.expertDescription}
                        carIsVerified={product.carIsVerified}
                        yearOfProduction={product.yearOfProduction}
                        mileage={product.milleage}
                        horsePower={product.horsePower}
                        engineCapacity={product.engineCapacity}
                        price={product.startingPrice}
                        carBrand={product.carBrand}
                        carModel={product.carModel}
                        fuelType={product.fuelType}
                    />
                ))}
            </Container>
            <Footer />
        </>
    );
};

export default Auction;
