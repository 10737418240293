import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/thumbs';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { Thumbs, Navigation, Scrollbar } from 'swiper/modules';

function EcommerceSlider(aParams) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    // Array of image IDs for demo purposes
    const { images } = aParams;

    return (
        <section className="py-24">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mb-16 flex flex-start border-b-2 border-black">
                    <h2 className="flex items-center text-xl font-medium title-font text-white px-4 py-2 uppercase bg-zinc-900">Raport / Opis rzeczoznawcy</h2>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2">
                    <div className="slider-box w-full h-full max-lg:mx-auto mx-0">
                        <Swiper
                            modules={[Thumbs,Navigation]}
                            spaceBetween={30}
                            slidesPerView={1}
                            navigation
                            thumbs={{ swiper: thumbsSwiper }}
                            className="main-slide-carousel relative mb-6"
                        >
                            {images.map((imgUrl, key) => (
                                <SwiperSlide key={key}>
                                    <img
                                        src={imgUrl || ""}
                                        alt="Summer Travel Bag image"
                                        className="h-full w-full object-cover"
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>

                        <Swiper
                            onSwiper={setThumbsSwiper}
                            loop={false}
                            spaceBetween={10}
                            slidesPerView={Math.min(5, 5)} // Show up to 5 images, scrollable if more
                        >
                            {images.map((imgUrl, key) => (
                                <SwiperSlide key={key}>
                                    <img
                                        src={imgUrl || ""}
                                        alt="Summer Travel Bag thumbnail"
                                        className="cursor-pointer transition-all duration-500 object-cover"
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>

                    <div className="w-full max-lg:max-w-[608px] lg:pl-8 xl:pl-16 max-lg:mx-auto max-lg:mt-8">
                        {/* content */}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default EcommerceSlider;
