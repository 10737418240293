export const Config = {
    contact: {
        phone: "(+48) 537 682 237",
    },
    socialMedia: {
        facebook: "https://www.facebook.com/smartcarspl",
        instagram: "https://www.instagram.com/smart_cars.pl/profilecard/?igsh=dHVkazRudXkzcTI%3D",
        whatsapp: "https://wa.me/+48537682237"
    },
    headlessConfig: {
        baseURL: 'https://smart-cars.pl',
        baseURLMedia: 'https://smart-cars.pl/acp/storage/uploads',
        API_KEY: 'USR-5b0b4b1efa21d8a55704478cb5ae7bb8c2397830'
    }
}

