import React from 'react'
import { Link } from 'react-router-dom'
import { Config } from '../../Config'

const EcommerceCardProduct = ({ id, title, img, auctionDate, expertDescription, carIsVerified, yearOfProduction, mileage, horsePower, engineCapacity, price, fuelType }) => {
    return (
        <div className="flex flex-col lg:flex-row w-full mx-auto border border-gray-300 overflow-hidden my-4 flex p-5 bg-white xl:mx-32 shadow-md">
            <div className="lg:w-1/4 flex-shrink-0">
                <Link to={id}>
                    <img src={img} alt={title} className="w-full h-auto object-cover" />
                </Link>
            </div>

            <div className="flex-1 py-5 lg:px-5 lg:py-0 flex flex-col justify-between">
                <div className="">
                    <Link to={id}>
                        <h2 className="text-2xl font-semibold text-gray-900 mb-2">{title || ""}</h2>
                    </Link>

                    <div className="flex space-x-2 mb-3">
                        {expertDescription && <span className="flex items-center justify-center bg-green-100 text-green-800 text-xs font-medium px-3 py-1 rounded-full">
                            <svg className='h-4 w-4 mr-2' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fillRule="evenodd" clipRule="evenodd" d="M12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z" fill="#166534cc"></path> </g></svg>
                            Raport rzeczoznawcy</span>}
                        {carIsVerified && <span className="flex items-center justify-center bg-green-100 text-green-800 text-xs font-medium px-3 py-1 rounded-full">
                            <svg className='h-4 w-4 mr-2' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fillRule="evenodd" clipRule="evenodd" d="M12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z" fill="#166534cc"></path> </g></svg>
                            Auto zweryfikowane</span>}
                        {fuelType && <span className="flex items-center justify-center bg-green-100 text-green-800 text-xs font-medium px-3 py-1 rounded-full">
                            <svg className='h-4 w-4 mr-2' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#166534cc"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 5C4 4.44772 4.44772 4 5 4H12C12.5523 4 13 4.44772 13 5V21H4V5Z" stroke="#166534cc" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M13 11.5H18V19C18 20.1046 18.8954 21 20 21V21C21.1046 21 22 20.1046 22 19V9M17.5 2L20.5 4.66667M20.5 4.66667L22 6V9M20.5 4.66667V9H22" stroke="#166534cc" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M15 21L2 21" stroke="#166534cc" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M10 8L7 8" stroke="#166534cc" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                            {fuelType || ""}</span>}
                    </div>
                </div>


                <div className="text-sm text-gray-700 space-y-1 mb-3">
                    <p><span className="font-semibold">Rok produkcji:</span> {yearOfProduction || ""}r.</p>
                    <p><span className="font-semibold">Przebieg:</span> {mileage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || ""} km</p>
                    <p><span className="font-semibold">Moc:</span> {horsePower || ""} KM</p>
                    <p><span className="font-semibold">Pojemność silnika:</span> {engineCapacity || ""}L</p>
                </div>
                <div className="text-md text-gray-900 mb-1">
                    Cena wywoławcza: <span className='font-bold text-2xl'>{price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " PLN" || ""}<span className='text-xs'> (brutto)</span></span>
                </div>
            </div>

            <div className="lg:w-1/4 flex flex-col lg:items-end text-right lg:justify-between space-y-2">
                <div>
                    <span className="text-sm font-medium text-gray-600">Data aukcji: <span className="bg-blue-100 text-blue-800 text-xs font-medium px-3 py-1 rounded-full">{auctionDate || ""}</span></span>
                </div>

                <div className="flex flex-col gap-2">
                    <button className="bg-white border-2 border-maincolor text-maincolor px-4 py-2 text-sm font-medium">
                        <Link target="_blank" to={Config.socialMedia.whatsapp}>
                            Zapytaj na WhatsApp
                        </Link>
                    </button>
                    <button className="bg-maincolor text-white px-4 py-2 text-sm font-medium hover:bg-maincolor">
                        <Link to={id}>
                            Zobacz szczegóły
                        </Link>
                    </button>
                </div>
            </div>
        </div>

    )
}

export default EcommerceCardProduct