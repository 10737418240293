import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/thumbs';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { Thumbs, Navigation } from 'swiper/modules';
import { Config } from '../../Config';
import { Link } from 'react-router-dom';

function EcommerceHero(aParams) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    // Hardcoded images and car details for demonstration
    const { images, title, auctionDate, yearOfProduction, mileage, horsePower, engineCapacity, price, carBrand, gearbox } = aParams;

    return (
        <section className="py-24 bg-neutral-50">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
                    <div className="slider-box w-full h-full max-lg:mx-auto mx-0">
                        <Swiper
                            modules={[Thumbs, Navigation]} // Dodaj moduł Navigation
                            spaceBetween={30}
                            slidesPerView={1}
                            thumbs={{ swiper: thumbsSwiper }}
                            navigation // Włącz nawigację (strzałki)
                            className="main-slide-carousel relative mb-6"
                        >
                            {images.map((imgUrl, key) => (
                                <SwiperSlide key={key}>
                                    <img
                                        src={imgUrl}
                                        alt={`${title} image`}
                                        className="h-full w-full object-cover"
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>

                        <Swiper
                            onSwiper={setThumbsSwiper}
                            loop={false}
                            spaceBetween={10}
                            slidesPerView={Math.min(5, 5)}
                        >
                            {images.map((imgUrl, key) => (
                                <SwiperSlide key={key}>
                                    <img
                                        src={imgUrl}
                                        alt={`${title} thumbnail`}
                                        className="cursor-pointer transition-all duration-500 object-cover "
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>

                    {/* Card content for car auction details */}
                    <div className="w-full ">
                        <div className="bg-white shadow-lg p-8">
                            <h2 className="text-3xl font-bold text-neutral-800">{title}</h2>
                            <p className="text-neutral-500 mt-2 mb-6">{carBrand}</p>

                            <div className="space-y-4">
                                <div className="flex justify-between border-b pb-2">
                                    <span className="text-neutral-500">Data aukcji</span>
                                    <span className="font-semibold bg-blue-100 text-blue-800 text-xs font-medium px-3 py-1 rounded-full">{auctionDate}</span>
                                </div>
                                <div className="flex justify-between items-end border-b pb-2">
                                    <span className="text-neutral-500">Cena wywoławcza</span>
                                    <span className="text-neutral-800 font-bold text-xl sm:text-4xl">{price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} PLN</span>
                                </div>

                                <div className="flex">
                                    <div id="alert" className="font-semibold bg-blue-100 text-blue-800 text-xs font-medium px-3 py-1 rounded-full flex items-center">
                                        <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 17V11" stroke="#1e40af" strokeWidth="1.5" strokeLinecap="round"></path> <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#1e40af"></circle> <path d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z" stroke="#1e40af" strokeWidth="1.5"></path> </g></svg>
                                        Dla zainteresowanych klientów możliwość dosłania szczegółowych zdjęć.
                                    </div>
                                </div>


                                <div className="flex flex-wrap gap-2 mt-6">
                                    <div className="flex-1 bg-neutral-100 text-neutral-700  px-4 py-3 text-center">
                                        <div className="font-semibold">Rok</div>
                                        <div className="text-neutral-600 text-sm text-nowrap">{yearOfProduction}</div>
                                    </div>
                                    <div className="flex-1 bg-neutral-100 text-neutral-700  px-4 py-3 text-center">
                                        <div className="font-semibold">Przebieg</div>
                                        <div className="text-neutral-600 text-sm text-nowrap">{mileage} km</div>
                                    </div>
                                    <div className="flex-1 bg-neutral-100 text-neutral-700 px-4 py-3 text-center">
                                        <div className="font-semibold">Pojemność</div>
                                        <div className="text-neutral-600 text-sm text-nowrap">{engineCapacity}L</div>
                                    </div>
                                    <div className="flex-1 bg-neutral-100 text-neutral-700 px-4 py-3 text-center">
                                        <div className="font-semibold">Moc</div>
                                        <div className="text-neutral-600 text-sm text-nowrap">{horsePower}Km</div>
                                    </div>
                                    <div className="flex-1 bg-neutral-100 text-neutral-700 px-4 py-3 text-center">
                                        <div className="font-semibold">Skrzynia</div>
                                        <div className="text-neutral-600 text-sm text-nowrap">{gearbox}</div>
                                    </div>
                                </div>

                                <div className="mt-8 flex flex-col md:flex-row md:gap-0">
                                    <button className="w-full bg-white border border-maincolor text-maincolor px-6 py-2 text-sm font-semibold  transition duration-300">
                                        <Link target="_blank" to={Config.socialMedia.whatsapp}>
                                            Zapytaj na WhatsApp
                                        </Link>
                                    </button>
                                    <button className="w-full bg-maincolor text-white px-6 py-2 text-sm font-semibold hover:bg-opacity-90 transition duration-300">
                                        <Link to={"tel:" + Config.contact.phone} className='flex flex-col'>
                                            <span>Zapytaj o szczegóły</span>
                                            <span>{Config.contact.phone}</span>
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default EcommerceHero;
